body {
  background-color: whitesmoke;
}

.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* LAYOUT */

.layout .logo {
  float: left;
  display: flex;
  margin: 0px 20px;
}

.layout .logo-text {
  color: white;
  font-size: 20px;
  font-weight: 600;
  padding: 0px 10px
}

.layout .logo img {
  height: 40px;
  margin: 10px;
  width: auto;
  /* float: left; */
}

.layout .ant-menu-item-selected {
      color: #fff !important;
    background-color: #1677ff !important;
    border-radius: 0px !important;
}


/* Vendors */

.breadcrumb-container {
  margin: 10px 15px;
}

/* Vendors table & Transaction table */

.vendor-list-table-container {
  margin: 20px;
  border-radius: 5px;
}

.vendor-list-table-action-panel, .transaction-list-table-action-panel {
  margin: 10px 0px;
}

.record-transaction-btn, .refresh-transaction-btn {
  float: right;
}

/* .vendor-list-table-action-panel .vendor-btns-container {
  display: flex;
} */

.vendor-list-table-action-panel .create-vendor-btn {
  float: right;
}

/* Vendors create modal */

/* .modal-title {
  text-align: center;
} */

.vendor-form {
  max-width: 700px;
}

/* Vendors view screen */

.vendor-view-container {
  margin: 20px;
}